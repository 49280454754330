import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
//pages
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import ClientOnboarding from "./pages/ClientOnboarding";
import ClientOnboardingSections from "./pages/ClientOnboarding/ClientOnboardingForms";
import LoginLayout from "./layout/LoginLayout";
import AdminLayout from "./layout/AdminLayout";
import Layout from "./layout/LayoutWithSidebar";
import EmployeeOnboarding from "./pages/EmployeeOnboarding";
import SetAuth from "./pages/SetAuth";
import EmployeeOnboardingSections from "./pages/EmployeeOnboarding/EmployeeOnboardingForms";
import Offerletter from "./pages/Offerletter";
import DashBoard from "./pages/Dashboard";
import "./App.scss";
import CompanySettings from "./pages/CompanySettings";
import Logout from "./layout/Logout";
import Profile from "./pages/Profile";
import Signatory from "./pages/Signatory";
import EmployerStateTax from "./pages/EmployerStateTax";
import Payroll from "./pages/Payroll";
import NotFound from "./layout/NotFound";
import SignDocuments from "./pages/SignDocuments";
import SignDocumentsDetails from "./components/SignDocumentDetails";
import EmployeeStatus from "./pages/EmployeeStatus";
import Settings from "./pages/Settings";
import IFrame from "./components/iframe";
import TwoFactorPage from "./pages/TwoFactorAuthentication";
import EmployeeTwoFactorAuthPage from "./pages/TwoFactorAuthEmployee";
import MobileVerificationPage from "./pages/TwoFactorAuthEmployee/MobileVerifications.js";
import OtpVerificationPage from "./pages/OtpVerification/index.js";
import OtpSuccess from "./pages/OtpSuccess/index.js";
import TimeCardsPage from "./pages/timeCards";
import PayPeriodSummaryPage from "./pages/payPeriodSummary";
import TimeOffCalenderPage from "./pages/TimeOffCalender";
import TimeOffCardsPage from "./pages/timeOffCards";
import MultiCardApprovalPage from "./pages/MultiCardApproval";
import MultiCardDetailPage from "./pages/MultiCardDetail";
import CustomReport from "./pages/customReport/index.js";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" exact={true} element={<LoginLayout />}>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" exact={true} element={<Login />} />
            <Route
              path="/forgot-password"
              exact={true}
              element={<ForgotPassword />}
            />
            <Route
              path="/reset-password/:id"
              exact={true}
              element={<ResetPassword />}
            />

            <Route
              path="/client-onboard/:id"
              exact={true}
              element={<ClientOnboarding />}
            />
            <Route
              path="/employee-onboard/:id"
              exact={true}
              element={<EmployeeOnboarding />}
            />
          </Route>
          <Route path="/" exact={true} element={<AdminLayout />}>
            <Route
              path="/client-onboard-sections"
              exact={true}
              element={<ClientOnboardingSections />}
            />
            <Route
              path="/employee-onboard-sections"
              exact={true}
              element={<EmployeeOnboardingSections />}
            />
            <Route
              path="/offer-letter"
              exact={true}
              element={<Offerletter />}
            />
          </Route>
          <Route path="/" exact={true} element={<Layout />}>
            <Route path="/dashboard" exact={true} element={<DashBoard />} />
            <Route
              path="/company-settings"
              exact={true}
              element={<CompanySettings />}
            />
            <Route
              path="/manage-pay-schedule"
              exact={true}
              element={<IFrame />}
            />
            <Route path="/profile" exact={true} element={<Profile />} />
            <Route path="/profile/:id" exact={true} element={<Profile />} />
            <Route path="/profile/:notification" element={<Profile />} />
            <Route
              path="/profile-view-task/:id/profile/:id"
              element={<Profile />}
            />
            <Route path="/signatory" exact={true} element={<Signatory />} />
            <Route
              path="/employer-state-tax"
              exact={true}
              element={<EmployerStateTax />}
            />
            <Route path="/payroll" exact={true} element={<Payroll />} />
            <Route
              path="/sign-documents"
              exact={true}
              element={<SignDocuments />}
            />
            <Route
              path="/sign-documents-details"
              exact={true}
              element={<SignDocumentsDetails />}
            />
            <Route
              path="/employee-status"
              exact={true}
              element={<EmployeeStatus />}
            />
            <Route path="/settings" exact={true} element={<Settings />} />
            <Route
              path="/two-factor-auth"
              exact={true}
              element={<TwoFactorPage />}
            />
            <Route
              path="/timekeeping"
              exact={true}
              element={<TimeCardsPage />}
            />
            <Route
              path="/pay-period-summary"
              exact={true}
              element={<PayPeriodSummaryPage />}
            />
            <Route
              path="/time-off-calender"
              exact={true}
              element={<TimeOffCalenderPage />}
            />
            <Route
              path="/time-off-cards"
              exact={true}
              element={<TimeOffCardsPage />}
            />
            <Route
              path="/multi-card-approval"
              exact={true}
              element={<MultiCardApprovalPage />}
            />
            <Route
              path="/multi-card-detail"
              exact={true}
              element={<MultiCardDetailPage />}
            />
            <Route
              path="/custom-report"
              exact={true}
              element={<CustomReport />}
            />
          </Route>
          <Route path="/set-auth/:data" exact={true} element={<SetAuth />} />
          <Route
            path="/employee-two-fact-auth"
            exact={true}
            element={<EmployeeTwoFactorAuthPage />}
          />
          <Route
            path="/mobile-verification"
            exact={true}
            element={<MobileVerificationPage />}
          />
          <Route
            path="/otp-verification"
            exact={true}
            element={<OtpVerificationPage />}
          />
          <Route path="/otp-success" exact={true} element={<OtpSuccess />} />
          <Route path="/logout" exact={true} element={<Logout />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
