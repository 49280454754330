import React from "react";
import "./style.scss";

const LoginFooter = () => {
  return (
    <>
      <footer className="login-footer">
        <div>
          Copyright © {new Date().getFullYear()} Guhroo, Inc. <br />
          <a href="https://www.guhroo.co/privacy-policy/">Privacy Policy</a>{" "}
        </div>
      </footer>
    </>
  );
};

export default LoginFooter;
