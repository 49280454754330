import React, { useState, useEffect } from "react";
import { Col, Row, Form, Button, Table } from "react-bootstrap";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import accountBalance from "../../../assets/images/account_balance_wallet.svg";
import deleteIcon from "../../../assets/images/delete.svg";
import editIcon from "../../../assets/images/edit.svg";
import "./style.scss";
import Loader from "../../../components/Loader";
import { postData } from "../../../api";
import { toast } from "react-toastify";
import {
  onlyCharacterWithSpace,
  mustNumbers,
  emptySpace,
  autocloseTiming,
  FloattingNo,
} from "../../../api/regex";
import FormModal from "../../../components/FormModal";

const BankingTab = ({ bankingData, userid, gustoUser }) => {
  const [loading, setLoading] = useState(false);
  const [addAccountshow, setAddAccountShow] = useState(false);
  const [addAccount, setAddAccount] = useState([]);
  const [newAccount, setNewAccount] = useState({
    deposit_preference: "",
    nickname: "",
    type: "",
    routing_num: "",
    acc_num: "",
    pay_order: "",
    bank_account_number: "",
    percentage: "",
    remaining_balance: "0",
    dd_type: "",
    actions: "",
  });
  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState({});
  const [modalState, setModalState] = useState("Add");
  // Define keys to be removed
  const keysToRemove = [
    "pay_order",
    "nickname",
    "type",
    "routing_num",
    "acc_num",
    "bank_account_number",
    "percentage",
    "amount",
    "dd_type",
  ];

  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteRow, setDeletedRow] = useState([]);

  const handleDeleteClose = () => setDeleteShow(false);

  useEffect(() => {
    if (bankingData && Object.keys(bankingData).length !== 0) {
      setAddAccount(bankingData);
      setNewAccount(bankingData);
    }
  }, [bankingData]);

  const handleAddAccountClose = () => {
    setAddAccountShow(false);
    setErrors({});
  };

  // edit bank
  const editBank = (row) => {
    setModalState("Edit");
    setAddAccountShow(true);
    setErrors({});
    setValidated(false);
    setNewAccount((prev) => ({
      ...prev,
      nickname: row?.nickname,
      type: row?.type,
      routing_num: row?.routing_num,
      acc_num: row?.acc_num,
      bank_account_number: row?.acc_num,
      percentage: row?.percentage,
      amount: row?.amount,
      dd_type: row?.dd_type,
      pay_order: row?.pay_order,
    }));
  };

  const addBankAccount = () => {
    setAddAccountShow(true);
    setModalState("Add");
    setErrors({});
    setValidated(false);
    // Remove the specified keys and values for add new data (to avoid edit row data)
    keysToRemove.forEach((key) => {
      delete newAccount[key];
    });
  };

  const handleDeleteBank = async (row) => {
    setDeleteShow(true);
    setDeletedRow(row);
  };

  const deleteBank = async () => {
    setDeleteShow(false);
    setLoading(true);
    let data = {
      user_id: userid,
      action: "delete",
      stage: "direct_deposit",
      ...deleteRow,
    };
    const res = await postData("profile", {}, data);
    if (res.status === true) {
      setLoading(false);
      let loadedData = res?.data?.direct_deposit;
      setAddAccount(loadedData);
      toast.success(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };

  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setNewAccount((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (!!errors[name])
      setErrors({
        ...errors,
        [name]: null,
      });
  };

  const isValidRoutingNumber = (n) => {
    if (n.length !== 9 || isNaN(n)) {
      return false;
    } else {
      let digits = n.split("");
      var sum =
        digits[0] * 3 +
        digits[1] * 7 +
        digits[2] * 1 +
        digits[3] * 3 +
        digits[4] * 7 +
        digits[5] * 1 +
        digits[6] * 3 +
        digits[7] * 7 +
        digits[8] * 1;
      return sum % 10 === 0;
    }
  };

  const findFormErrors = () => {
    const {
      deposit_preference,
      routing_num,
      acc_num,
      bank_account_number,
      type,
      nickname,
      amount,
      percentage,
      pay_order,
    } = newAccount;
    const newErrors = {};

    if (deposit_preference === "") {
      newErrors.deposit_preference = "Please enter direct deposit preference.";
    }
    if (deposit_preference === "percentage") {
      if (!percentage || percentage === "")
        newErrors.percentage = "Please enter percentage.";
    }
    if (deposit_preference === "amount") {
      if (!amount || amount === "") newErrors.amount = "Please enter amount.";
      else if (!amount.toString()?.match(FloattingNo)) {
        newErrors.amount = "The amount should be numeric.";
      }
    }
    if (!routing_num || routing_num === "")
      newErrors.routing_num = "Please enter routing number.";
    else if (!routing_num.toString()?.match(mustNumbers)) {
      newErrors.routing_num = "The routing number should be numeric.";
    } else if (routing_num.length !== 9) {
      newErrors.routing_num = "The  routing number should be 9 characters";
    } else if (isValidRoutingNumber(routing_num) === false) {
      newErrors.routing_num = "Please enter a valid routing number.";
    }
    if (!acc_num || acc_num === "")
      newErrors.acc_num = "Please enter your account number.";
    else if (!acc_num.toString()?.match(mustNumbers)) {
      newErrors.acc_num = "The account number should be numeric.";
    } else if (acc_num.length < 5) {
      newErrors.acc_num =
        "The account number should be  5 to 25 characters long.";
    } else if (acc_num.length > 25) {
      newErrors.acc_num =
        "The account number should be  5 to 25 characters long.";
    }

    if (bank_account_number !== acc_num) {
      newErrors.bank_account_number =
        "Confirm account number is not matching with account number,";
    }
    if (!bank_account_number || bank_account_number === "") {
      newErrors.bank_account_number =
        "Please enter your confirm account number.";
    }
    if (!type || type === "") {
      newErrors.type = "Please enter account type.";
    }
    if (!nickname || nickname === "")
      newErrors.nickname = "Please enter your nick name.";
    else if (nickname.length < 3) {
      newErrors.nickname = "The nick name should be  3 to 40 characters long";
    } else if (nickname.length > 40) {
      newErrors.nickname = "The nick name should be  3 to 40 characters long";
    } else if (!nickname?.match(onlyCharacterWithSpace)) {
      newErrors.nickname = "The nick name should be alphabetic";
    } else if (!nickname?.match(emptySpace)) {
      newErrors.nickname = "The nick name should be  3 to 40 characters long";
    }
    if (modalState === "Add") {
      if (!pay_order || pay_order === "") {
        newErrors.pay_order = "Please enter pay order.";
      }
    }

    return newErrors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = findFormErrors();
    setValidated(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      addBankInfo();
    }
  };

  const addBankInfo = async () => {
    let copyClientObject = { ...newAccount };
    if (newAccount?.deposit_preference === "percentage") {
      delete copyClientObject.amount;
    } else if (newAccount?.deposit_preference === "amount") {
      delete copyClientObject.percentage;
    }
    delete copyClientObject.bank_account_number;
    // new api call made update_remaining_balance
    delete copyClientObject.remaining_balance;
    setLoading(true);

    let data = {
      user_id: userid,
      stage: "direct_deposit",
      ...copyClientObject,
    };

    const res = await postData("profile", {}, data);
    if (res.status === true) {
      setLoading(false);
      toast.success(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setAddAccountShow(false);
      let loadedData = res?.data?.direct_deposit;
      setAddAccount(loadedData);
      setNewAccount(loadedData);
      setErrors({});
      setValidated(false);
    } else {
      setAddAccountShow(false);
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };

  const handleBalance = (e) => {
    const updatedValue = e.target.checked ? "1" : "0";
    if (!gustoUser) {
      remainingBalance(updatedValue);
    }
    setNewAccount((prev) => ({
      ...prev,
      remaining_balance: updatedValue,
    }));
  };

  const remainingBalance = async (balance) => {
    let data = {
      user_id: userid,
      remaining_balance: balance,
    };
    setLoading(true);
    const res = await postData("update_remaining_balance", {}, data);
    if (res.status === true) {
      setLoading(false);
      toast.success(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      let loadedData = res?.data?.direct_deposit;
      setNewAccount({
        remaining_balance: loadedData?.remaining_balance,
        deposit_preference: newAccount?.deposit_preference,
      });
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };
  useEffect(() => {
    setNewAccount(prevState => ({
      ...prevState,
      deposit_preference: "percentage"
    }));
  }, []);
  return (
    <>
      {!loading && (
        <div className="banking_container">
          <div>
            <h5 className="tabs_main_heading mb-3">
              <span className="me-1">
                <img src={accountBalance} alt="" srcSet="" />
              </span>
              Direct deposit details
            </h5>
            <Row className="d-flex justify-content-between align-items-center mb-4">
              <Col xs={12} lg={4}>
                <Form.Group className="mb-1" controlId="deposit_preference">
                  <Form.Label>
                    Direct deposit preference{" "}
                    <span className="asterisk">*</span>{" "}
                  </Form.Label>
                  <Form.Select
                    onChange={handleInput}
                    name="deposit_preference"
                    value={newAccount?.deposit_preference}
                    required
                    size="lg"
                  >
                    {/* <option value="">Select account type</option> */}
                    <option value="percentage">Percentage</option>
                    <option value="amount">Amount</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.deposit_preference}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            {!gustoUser && (
              <Form>
                <Form.Group className="mb-3" controlId="remaining_balance">
                  <div>
                    <Form.Check
                      inline
                      label="Remaining balance to first account"
                      name="remaining_balance"
                      type="checkbox"
                      checked={
                        newAccount.remaining_balance?.toString() === "1"
                          ? true
                          : false
                      }
                      value="1"
                      onChange={(e) => handleBalance(e)}
                      id="remaining_balance"
                    />
                  </div>
                </Form.Group>
              </Form>
            )}

            {/* Large Screen Table Starts */}

            <Table
              responsive
              className="mt-4 header_bg_table d-none d-md-table custom-bank-table"
            >
              <thead>
                <tr>
                  <th>Nick Name</th>
                  <th>Type</th>
                  <th>Routing Number</th>
                  <th>Account no.</th>
                  {newAccount?.deposit_preference === "percentage" && (
                    <th>Percentage</th>
                  )}
                  {newAccount?.deposit_preference === "amount" && (
                    <th>Amount</th>
                  )}
                  <th>Pay order</th>
                  <th></th>
                </tr>
              </thead>
              {addAccount?.bank_info?.length !== 0 ? (
                <tbody>
                  {addAccount?.bank_info?.map((account, i) => {
                    return (
                      <tr key={i}>
                        <td>{account?.nickname}</td>
                        <td>{account?.type}</td>
                        <td>
                          {account?.routing_num?.replace(/.(?=.{4})/g, "X")}
                        </td>
                        <td>{account?.acc_num?.replace(/.(?=.{4})/g, "X")}</td>
                        {newAccount?.deposit_preference === "percentage" && (
                          <td>{account?.percentage}</td>
                        )}
                        {newAccount?.deposit_preference === "amount" && (
                          <td>{account?.amount}</td>
                        )}
                        <td>{account?.pay_order}</td>
                        <td>
                          {addAccount?.actions && (
                            <div className="edit_delete_container d-flex">
                              <div
                                className="d-flex flex-column align-items-center me-3"
                                onClick={() => editBank(account)}
                              >
                                <MdOutlineModeEditOutline />
                                <p>Edit</p>
                              </div>
                              <div
                                className=" d-flex flex-column align-items-center"
                                onClick={() => handleDeleteBank(account)}
                              >
                                <RiDeleteBin5Line />
                                <p>Delete</p>
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="7">
                      <h4 className="no-data mb-0">No data found</h4>
                    </td>
                  </tr>
                </tbody>
              )}
            </Table>
            {/* Large Screen Table Ends */}

            {/* Small Screen Table */}
            {addAccount?.bank_info?.length !== 0 ? (
              addAccount?.bank_info?.map((account, i) => {
                return (
                  <Row
                    className="d-block d-md-none mobile_accounts_table mt-4"
                    key={i}
                  >
                    <Row className="table_xs_row">
                      <Col xs={5}>Nick Name</Col>
                      <Col xs={7}>{account?.nickname}</Col>
                    </Row>
                    <Row className="table_xs_row">
                      <Col xs={5}>Type</Col>
                      <Col xs={7}>{account?.type}</Col>
                    </Row>
                    <Row className="table_xs_row">
                      <Col xs={5}>Routing Number</Col>
                      <Col xs={7}>
                        {account?.routing_num?.replace(/.(?=.{4})/g, "X")}
                      </Col>
                    </Row>
                    <Row className="table_xs_row">
                      <Col xs={5}>Account no.</Col>
                      <Col xs={7}>
                        {account?.acc_num?.replace(/.(?=.{4})/g, "X")}
                      </Col>
                    </Row>
                    {newAccount?.deposit_preference === "percentage" && (
                      <Row className="table_xs_row">
                        <Col xs={5}>Percentage</Col>
                        <Col xs={7}>{account?.percentage}</Col>
                      </Row>
                    )}
                    {newAccount?.deposit_preference === "amount" && (
                      <Row className="table_xs_row">
                        <Col xs={5}>Amount</Col>
                        <Col xs={7}>{account?.amount}</Col>
                      </Row>
                    )}
                    <Row className="table_xs_row">
                      <Col xs={5}>Pay order</Col>
                      <Col xs={7}>{account?.pay_order}</Col>
                    </Row>
                    {addAccount?.actions && (
                      <Row>
                        <Col
                          xs={6}
                          className="d-flex justify-content-center mobile_icon"
                          onClick={() => handleDeleteBank(account)}
                        >
                          <span>
                            <img src={deleteIcon} alt="" srcSet="" />
                          </span>
                          <span className="ms-2">Delete</span>
                        </Col>

                        <Col
                          xs={6}
                          className="d-flex justify-content-center mobile_icon"
                          onClick={() => editBank(account)}
                        >
                          <span>
                            <img src={editIcon} alt="" srcSet="" />
                          </span>
                          <span className="ms-2">Edit</span>
                        </Col>
                      </Row>
                    )}
                  </Row>
                );
              })
            ) : (
              <h4 className="no-data mb-0 d-block d-md-none ">No data found</h4>
            )}

            {/* Small Screen table ends */}

            <p>User can only add maximum of 3 accounts.</p>
            {/* Add Account button starts */}
            <Row className="d-flex justify-content-end">
              <Col sm={12} md={4} className="d-flex justify-content-end">
                <Button
                  variant="primary"
                  className="save_btn"
                  onClick={addBankAccount}
                  disabled={addAccount?.bank_info?.length < 3 ? false : true}
                >
                  Add Account
                </Button>
              </Col>
            </Row>
            {/* Add Account button ends */}

            {/* Add account modal starts */}
            <Row>
              <Col className="d-flex justify-content-end">
                <FormModal
                  heading={`${modalState} Direct Deposit details`}
                  show={addAccountshow}
                  size="lg"
                  onClose={handleAddAccountClose}
                  backdrop="static"
                  modalClassName="view_task_modal"
                >
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    className="mt-3"
                  >
                    <Row>
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="nickname">
                          <Form.Label className="me-2">
                            Account nickname <span className="asterisk">*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter account nickname"
                            value={newAccount?.nickname}
                            onChange={handleInput}
                            isInvalid={!!errors.nickname}
                            name="nickname"
                            maxLength="40"
                            required
                            disabled={addAccount?.actions ? false : true}
                            className={`${
                              errors.nickname ? "invalid_input" : "normal_input"
                            }`}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.nickname}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mb-3" controlId="type">
                          <Form.Label className="me-2">
                            Account type <span className="asterisk">*</span>{" "}
                          </Form.Label>
                          <Form.Select
                            onChange={handleInput}
                            name="type"
                            value={newAccount?.type}
                            required
                            // size="lg"
                            isInvalid={!!errors.type}
                            disabled={addAccount?.actions ? false : true}
                            style={{
                              backgroundImage: "none",
                              fontSize: "14px",
                            }}
                            className={`${
                              errors.type ? "invalid_input" : "normal_input"
                            }`}
                          >
                            <option value="">Select account type</option>
                            <option value="CHECKING"> Checking</option>
                            <option value="SAVINGS"> Savings</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors.type}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <Form.Group className="mb-3" controlId="routing_num">
                          <Form.Label className="me-2">
                            Routing number <span className="asterisk">*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter routing number"
                            value={newAccount?.routing_num}
                            onChange={handleInput}
                            isInvalid={!!errors.routing_num}
                            name="routing_num"
                            required
                            maxLength="9"
                            disabled={addAccount?.actions ? false : true}
                            className={`${
                              errors.routing_num
                                ? "invalid_input"
                                : "normal_input"
                            }`}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.routing_num}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mb-3" controlId="acc_num">
                          <Form.Label className="me-2">
                            Account number <span className="asterisk">*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter account number"
                            value={newAccount?.acc_num}
                            onChange={handleInput}
                            isInvalid={!!errors.acc_num}
                            name="acc_num"
                            required
                            disabled={addAccount?.actions ? false : true}
                            className={`${
                              errors.acc_num ? "invalid_input" : "normal_input"
                            }`}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.acc_num}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <Form.Group
                          className="mb-3"
                          controlId="bank_account_number"
                        >
                          <Form.Label className="me-2">
                            Confirm account number{" "}
                            <span className="asterisk">*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Enter Confirm account number"
                            value={newAccount?.bank_account_number}
                            onChange={handleInput}
                            isInvalid={!!errors.bank_account_number}
                            name="bank_account_number"
                            required
                            disabled={addAccount?.actions ? false : true}
                            className={`${
                              errors.bank_account_number
                                ? "invalid_input"
                                : "normal_input"
                            }`}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.bank_account_number}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col
                        lg={6}
                        className={
                          newAccount?.deposit_preference === "percentage"
                            ? "d-block"
                            : "d-hide"
                        }
                      >
                        <Form.Group className="mb-1" controlId="percentage">
                          <Form.Label>
                            Percentage <span className="asterisk">*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter percentage"
                            value={newAccount?.percentage}
                            onChange={handleInput}
                            isInvalid={!!errors.percentage}
                            name="percentage"
                            required
                            disabled={addAccount?.actions ? false : true}
                            className={`${
                              errors.percentage
                                ? "invalid_input"
                                : "normal_input"
                            }`}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.percentage}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col
                        lg={6}
                        className={
                          newAccount?.deposit_preference === "amount"
                            ? "d-block"
                            : "d-hide"
                        }
                      >
                        <Form.Group className="mb-1" controlId="amount">
                          <Form.Label>
                            Amount <span className="asterisk">*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter amount"
                            value={newAccount?.amount}
                            onChange={handleInput}
                            isInvalid={!!errors.amount}
                            name="amount"
                            required
                            disabled={addAccount?.actions ? false : true}
                            className={`${
                              errors.amount ? "invalid_input" : "normal_input"
                            }`}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.amount}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <Form.Group className="mb-1" controlId="pay_order">
                          <Form.Label>
                            Pay order <span className="asterisk">*</span>{" "}
                          </Form.Label>
                          {modalState === "Add" ? (
                            <>
                              <Form.Select
                                onChange={handleInput}
                                name="pay_order"
                                value={newAccount?.pay_order}
                                required
                                size="lg"
                                isInvalid={!!errors.pay_order}
                                style={{
                                  backgroundImage: "none",
                                  fontSize: "14px",
                                }}
                                className={`${
                                  errors.pay_order
                                    ? "invalid_input"
                                    : "normal_input"
                                }`}
                              >
                                <option value="">Select</option>
                                {addAccount?.pay_order_list &&
                                  addAccount?.pay_order_list.map(
                                    (item, index) => (
                                      <option key={item.id} value={item.value}>
                                        {item.value}
                                      </option>
                                    )
                                  )}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                {errors.pay_order}
                              </Form.Control.Feedback>
                            </>
                          ) : (
                            <Form.Control
                              type="text"
                              placeholder="Enter pay order"
                              value={newAccount?.pay_order}
                              name="pay_order"
                              disabled
                            />
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <div className="text-end">
                      <Button
                        type="button"
                        variant="secondary"
                        className="btn-default me-3 cancel-btn btn_border_clr"
                        onClick={handleAddAccountClose}
                      >
                        Cancel
                      </Button>
                      {addAccount?.actions && (
                        <Button
                          variant="primary"
                          className="btn_clr_white"
                          type="submit"
                        >
                          {modalState === "Add"
                            ? "Add account"
                            : "Update account"}
                        </Button>
                      )}
                    </div>
                  </Form>
                </FormModal>
              </Col>
            </Row>
            {/* Add account modal ends */}
          </div>
        </div>
      )}
      {/* delete bank */}
      {deleteShow && (
        <FormModal
          heading="Are you sure you want to delete ?"
          show={deleteShow}
          size="sm"
          onClose={handleDeleteClose}
          backdrop="static"
          modalClassName="confirmation_modal"
        >
          <div className="text-center">
            <Button
              variant="secondary"
              onClick={handleDeleteClose}
              className="btn_border_clr btn-default cancel-btn me-2"
            >
              Cancel
            </Button>
            <Button
              className="btn_clr_white"
              variant="primary"
              onClick={deleteBank}
            >
              Delete
            </Button>
          </div>
        </FormModal>
      )}
      {/* delete bank end */}
      {loading && <Loader />}
    </>
  );
};

export default BankingTab;