import React from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "../../assets/images/settings_icons/back_button.svg";
import "./style.scss";

export default function BackButtonComponent(props) {
  const { hasHistoryback, reDirectionpath } = props;
  const navigate = useNavigate();
  const handleBackSettings = () => {
    if (hasHistoryback) navigate(-1);
    else window.open(reDirectionpath, "_self");
  };
  return (
    <div className="setting-backbutton" onClick={handleBackSettings}>
      <img src={BackButton} alt="back-btn" />
      <h5>Back</h5>
    </div>
  );
}
