import React, { useState, useEffect } from "react";
import { Container, Form, Row, Col } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import "./style.scss";
import logo from "../../assets/images/logo.png";
import Button from "../../components/Form/Button";
import { NavLink } from "react-router-dom";
import { postData } from "../../api";
import { toast } from "react-toastify";
import LoginFooter from "../LoginFooter";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { spaceRegex, autocloseTiming } from "../../api/regex";
import { useLocation } from "react-router-dom";

const Login = () => {
  const location = useLocation();
  const currentPath = location.pathname + location.search;
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [loginObject, setLoginObject] = useState({
    username: "",
    password: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (currentPath === "/login") {
      document.body.classList.add("feedback-remove");
    } else {
      document.body.classList.remove("feedback-remove");
    }
  }, [currentPath === "/login"]);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setLoginObject((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (!!errors[name])
      setErrors({
        ...errors,
        [name]: null,
      });
  };

  const findFormErrors = () => {
    const { username, password } = loginObject;
    const newErrors = {};
    if (!username || username === "")
      newErrors.username = "Please enter a valid username.";
    if (!password || password === "")
      newErrors.password = "Please enter your password.";
    else if (!username?.match(spaceRegex)) {
      newErrors.username = "Please enter a valid username.";
    }
    return newErrors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = findFormErrors();
    setValidated(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      loginUser();
    }
  };

  const loginUser = async () => {
    setLoading(true);
    const res = await postData("user_login", {}, loginObject);
    if (res.status === true) {
      setLoading(false);
      localStorage.setItem("guhroo_token", res.token);
      localStorage.setItem("guhroo_user", res.user_type);
      localStorage.setItem("guhroo_user_details", JSON.stringify(res));
      localStorage.setItem("guhroo_onbord_status", res.onboard_status);
      localStorage.setItem(
        "user_completion_status ",
        res.user_completion_status
      );
      localStorage.setItem("company_name", res.company_name);
      if (res.auto_login_url) {
        window.open(res.auto_login_url, "_self");
      }
      if (res.message === "Your account is inactive") {
        toast.error(res.message, {
          theme: "colored",
          autoClose: autocloseTiming,
        });
      }
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      const newErrors = {};
      newErrors.password = res.message;
      setErrors(newErrors);
      setValidated(false);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="login-body">
        <div className="login-header">
          <img src={logo} alt="logo" />
        </div>
        <section className="login-section">
          <Container>
            <Row className="justify-content-center align-items-center">
              <Col md={5}>
                <div className="login-box">
                  <div className="content">
                    <p className="mb-2 mt-1"> Welcome to guhroo</p>
                    <h5 className="pb-3">Sign in</h5>

                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                      autoComplete="off"
                      className="custom-form"
                    >
                      <Form.Group className="mb-3" controlId="username">
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter username"
                          value={loginObject.username}
                          onChange={handleInput}
                          isInvalid={!!errors.username}
                          name="username"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.username}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="password">
                        <Form.Label>Password</Form.Label>
                        <InputGroup className="toggle-password">
                          <Form.Control
                            type={passwordShown ? "text" : "password"}
                            placeholder="Enter password"
                            value={loginObject.password}
                            onChange={handleInput}
                            isInvalid={!!errors.password}
                            name="password"
                            required
                          />
                          <InputGroup.Text onClick={togglePasswordVisiblity}>
                            {passwordShown ? <FiEye /> : <FiEyeOff />}
                          </InputGroup.Text>
                          <Form.Control.Feedback type="invalid">
                            {errors.password}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>

                      <div className="text-center mt-4">
                        <Button
                          type="submit"
                          disabled={loading}
                          loading={loading}
                          className="btn-primary text-white"
                        >
                          Sign in
                        </Button>
                      </div>

                      <div className="forgot-text my-3">
                        <NavLink to="/forgot-password">
                          {" "}
                          Forgot password ?{" "}
                        </NavLink>
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <LoginFooter />
    </>
  );
};

export default Login;
