import React from "react";
import PendingIcon from "../../assets/images/multi-card-approval/pending_icon.svg";
import ApproveIcon from "../../assets/images/multi-card-approval/approveIcon.svg";
import RejectIcon from "../../assets/images/multi-card-approval/rejectedIcon.svg";
import HourIcon from "../../assets/images/multi-card-approval/hour_icon.svg";
import ClockOutHourIcon from "../../assets/images/multi-card-approval/clock_out.svg";
import WarningIcon from "../../assets/images/timekeeping_icons/warning_icon.svg";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import { postData } from "../../api";
import { toast } from "react-toastify";
import { autocloseTiming } from "../../api/regex";
import moment from "moment";
import AddModal from "./addModal";

export default function MultiDetailCardTable(props) {
  const {
    tableDataArray,
    checkedValues,
    handleMainCheckboxChange,
    handleIndividualCheckboxChange,
    handleAddModalOpen,
    setApproveDeny,
    handleModalOpen,
    setEditAddEntry,
    ptoStatus,
    groupId,
    handleHolidayModalOpen,
    frequencyId,
    dateRangeData,
    setLoading,
    getEmployeeCardData,
    addModalErrors,
    calcTotalhrs,
    btnLoading,
    handleApproveData,
    editAddEntry,
    addModalIndividualData,
    modalInputData,
    handleOnChange,
    resetModalInputData,
    clockOutModal,
    setClockOutModal,
    setAddModalErrors,
    ptoPendingData,
    ptoApprovedData,
    ptoDeniedData,
    selectedAllIds,
    handleApiStart,
    handleApiEnd,
    totalHoursData,
  } = props;
  const isToday = (date) => {
    const today = new Date();
    const givenDate = new Date(date);
    return (
      today.getDate() === givenDate.getDate() &&
      today.getMonth() === givenDate.getMonth() &&
      today.getFullYear() === givenDate.getFullYear()
    );
  };
  const hasRunningCategoryToday = () => {
    const allPtoData = [
      ...ptoPendingData,
      ...ptoApprovedData,
      ...ptoDeniedData,
    ];
    return allPtoData.some(
      (data) => isToday(data.date) && data.category === "Running"
    );
  };
  const handleClockInTimerStart = async (apiData) => {
    handleApiStart();
    const now = new Date();
    const currentDate = now.toISOString().split("T")[0];
    const currentTime = now.toTimeString().split(" ")[0].substring(0, 5);

    const clockInPayLoadObj = {
      req_start_time: currentTime,
      pto_policy_id:
        apiData?.category_id === "-" || apiData?.type === "holiday"
          ? -1
          : apiData?.category_id,
      user_id: apiData?.user_id,
      date: currentDate,
      pay_frequency_id: frequencyId?.pay_frequency_id,
      pay_start_date: dateRangeData?.start_date,
      pay_end_date: dateRangeData?.end_date,
    };
    try {
      const res = await postData("timer_clock_in", {}, clockInPayLoadObj);
      if (res?.status === true) {
        setLoading(false);
        toast.success(res.message, {
          theme: "colored",
          autoClose: 3000,
        });
        getEmployeeCardData();
      } else {
        setLoading(false);
        toast.error(res.message, {
          theme: "colored",
          autoClose: 3000,
        });
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
    } finally {
      handleApiEnd();
    }
  };
  const handleClockOutTimerStop = async (apiData) => {
    handleApiStart();
    const now = new Date();
    const currentDate = now.toISOString().split("T")[0];
    const currentTime = now.toTimeString().split(" ")[0].substring(0, 5);

    const clockInDate = moment(apiData?.date, "MM/DD/YYYY").format(
      "YYYY-MM-DD"
    );
    if (clockInDate !== currentDate) {
      setClockOutModal(true);
      setEditAddEntry(true);
      setLoading(false);
      return;
    }
    const clockOutPayLoadObj = {
      req_start_time: apiData?.in,
      req_end_time: currentTime,
      pto_policy_id:
        apiData?.category_id === "-" || apiData?.category_id === 0
          ? -1
          : apiData?.category_id,
      user_id: apiData?.user_id,
      clock_in_date: moment(apiData?.date, "MM/DD/YYYY").format("YYYY-MM-DD"),
      clock_out_date: currentDate,
      pay_frequency_id: frequencyId?.pay_frequency_id,
      pay_start_date: dateRangeData?.start_date,
      pay_end_date: dateRangeData?.end_date,
      id: apiData?.id,
    };
    try {
      const res = await postData("timer_clock_ot", {}, clockOutPayLoadObj);
      if (res?.status === true) {
        setLoading(false);
        toast.success(res.message, {
          theme: "colored",
          autoClose: 3000,
        });
        getEmployeeCardData();
      } else {
        setLoading(false);
        toast.error(res.message, {
          theme: "colored",
          autoClose: 3000,
        });
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
    } finally {
      handleApiEnd();
    }
  };
  const handleCloseModal = () => {
    setClockOutModal(false);
    resetModalInputData();
    setAddModalErrors({});
  };

  const filteredTableDataArray =
    ptoStatus === "pending"
      ? tableDataArray.filter(
          (individualData) => individualData.week_status === 0
        )
      : tableDataArray;
  return (
    <>
      <div className="pto-table-container detail-table-container">
        <div className="pto-table-scroll">
          {filteredTableDataArray.length > 0 ? (
            <>
              <table className="pto-table">
                <thead>
                  <tr>
                    {(ptoStatus === "all" ||
                      ptoStatus === "pending" ||
                      ptoStatus === "editRequest") && (
                      <th style={{ width: "1%" }}>
                        {groupId === 5 || groupId === 8 ? null : (
                          <div className="d-flex align-items-center">
                            <input
                              type="checkbox"
                              className="me-0"
                              checked={
                                selectedAllIds ||
                                (checkedValues.length > 0 &&
                                  checkedValues.every((val) => val.isChecked))
                              }
                              onChange={handleMainCheckboxChange}
                            />
                          </div>
                        )}
                      </th>
                    )}
                    <th style={{ width: "10%", textAlign: "left" }}>Date</th>
                    <th style={{ width: "6%", textAlign: "left" }}>In</th>
                    <th style={{ width: "6%", textAlign: "left" }}>Out</th>
                    <th style={{ width: "10.5%", textAlign: "left" }}>Hours</th>
                    <th style={{ width: "10.5%", textAlign: "left" }}>
                      Category
                    </th>
                    <th style={{ width: "10.5%", textAlign: "left" }}>Notes</th>
                    <th style={{ width: "10%", textAlign: "left" }}>
                      <p style={{ margin: "0" }}>Job Location/</p>
                      <p style={{ margin: "0" }}>Job Code</p>
                    </th>
                    <th style={{ width: "12%", textAlign: "left" }}>
                      Time card status
                    </th>
                    <th style={{ width: "14%", textAlign: "left" }}>Action</th>
                    {/* )} */}
                  </tr>
                </thead>
                <tbody>
                  {filteredTableDataArray &&
                    filteredTableDataArray
                      // .slice(startIndex, endIndex)
                      .map((individualData, index) => (
                        <>
                          {" "}
                          {ptoStatus === "all" &&
                            individualData.week_status === 1 && (
                              <tr
                                key={`summary-${index}`}
                                className="summary-row-wrapper"
                              >
                                <td colSpan="4" className="span-week-wrapper">
                                  <h5>
                                    Week{" "}
                                    <span>
                                      {moment(individualData.week_start).format(
                                        "MM-DD-YYYY"
                                      )}{" "}
                                      -{" "}
                                      {moment(individualData.week_end).format(
                                        "MM-DD-YYYY"
                                      )}
                                    </span>
                                  </h5>
                                </td>
                                <td colSpan="2" className="span-week-wrapper">
                                  <h5>
                                    Regular Hours{" "}
                                    <span>{individualData.regular}</span>
                                  </h5>{" "}
                                </td>
                                <td colSpan="2" className="span-week-wrapper">
                                  <h5>
                                    Overtime Hours{" "}
                                    <span>{individualData.overtime}</span>
                                  </h5>{" "}
                                </td>
                                <td colSpan="2"></td>
                              </tr>
                            )}
                          {individualData.week_status === 0 && (
                            <tr key={`${individualData.user_id}-${index}`}>
                              <>
                                {ptoStatus === "all" ||
                                ptoStatus === "pending" ||
                                ptoStatus === "editRequest" ? (
                                  <td>
                                    {groupId === 5 || groupId === 8 ? null : (
                                      <>
                                        {individualData?.status ===
                                        "Pending" ? (
                                          <>
                                            {individualData?.type !==
                                              "time-card" ||
                                            individualData?.category ===
                                              "Running" ? null : (
                                              <div className="d-flex align-items-center">
                                                <input
                                                  type="checkbox"
                                                  className="me-3"
                                                  checked={
                                                    checkedValues[index]
                                                      ?.isChecked
                                                  }
                                                  onChange={() =>
                                                    handleIndividualCheckboxChange(
                                                      index
                                                    )
                                                  }
                                                />
                                              </div>
                                            )}
                                          </>
                                        ) : null}
                                      </>
                                    )}
                                  </td>
                                ) : null}
                              </>
                              <td>
                                {/* <Tooltip
                        placement="left"
                        overlay={
                          <div
                            style={{ wordBreak: "break-word" }}
                          >
                            {individualData?.date_label}
                          </div>
                        }
                        overlayClassName="timeoff-calender-tooltip"
                      > */}
                                <div>
                                  {individualData?.date_label}
                                  {/* {individualData?.employeeName?.substring(0, 15)}
                          {individualData?.employeeName?.length > 15
                            ? "..."
                            : ""} */}
                                </div>
                                {/* </Tooltip> */}
                              </td>
                              <td>{individualData.in}</td>
                              <td>{individualData.out}</td>
                              <td>{individualData.hours}</td>
                              <td>{individualData.category}</td>

                              <td>
                                {" "}
                                {individualData.notes === "-" ? (
                                  individualData.notes
                                ) : (
                                  <Tooltip
                                    placement="left"
                                    overlay={
                                      <div style={{ wordBreak: "break-word" }}>
                                        {individualData?.note_data !== ""
                                          ? individualData?.note_data
                                          : individualData.notes}
                                      </div>
                                    }
                                    overlayClassName="timeoff-calender-tooltip"
                                  >
                                    <div>
                                      {individualData.notes?.substring(0, 15)}
                                      {individualData.notes?.length > 15
                                        ? "..."
                                        : ""}
                                    </div>
                                  </Tooltip>
                                )}
                              </td>
                              <td>
                                {individualData?.job_location_title !== ""
                                  ? individualData?.job_location_title?.title
                                  : individualData?.job_code_title !== ""
                                  ? individualData?.job_code_title?.title
                                  : "-"}
                              </td>
                              <td>
                                <div className="pending-wrapper">
                                  {individualData?.status === "-" ? null : (
                                    <img
                                      src={
                                        individualData?.status === "Pending"
                                          ? PendingIcon
                                          : individualData.status === "Approved"
                                          ? ApproveIcon
                                          : RejectIcon
                                      }
                                      alt="pending-icon"
                                    />
                                  )}
                                  <p>
                                    {individualData.status === "Pending" &&
                                    individualData.is_edit === 1 &&
                                    groupId !== 5
                                      ? "Edit Request"
                                      : individualData.status}
                                  </p>
                                </div>{" "}
                              </td>
                              <td>
                                {individualData.hire === 1 ? (
                                  <div className="approve_deny_btn">
                                    <>
                                      {individualData?.id === "-" ? null : (
                                        <button
                                          onClick={() => {
                                            setApproveDeny(false);
                                            individualData.type === "holiday"
                                              ? handleHolidayModalOpen(
                                                  individualData
                                                )
                                              : handleModalOpen(individualData);
                                          }}
                                        >
                                          {groupId === 5 || groupId === 8
                                            ? individualData?.status ===
                                              "Pending"
                                              ? "EDIT"
                                              : "VIEW"
                                            : "VIEW"}
                                        </button>
                                      )}
                                      {individualData.category ===
                                      "Running" ? null : (
                                        <button
                                          onClick={() => {
                                            handleAddModalOpen(
                                              individualData?.date_label
                                            );
                                            setEditAddEntry(true);
                                          }}
                                        >
                                          ADD
                                        </button>
                                      )}
                                    </>
                                    {groupId === 5 || groupId === 8 ? null : (
                                      <>
                                        {isToday(individualData.date_label) && (
                                          <>
                                            {individualData.in !== "-" &&
                                            individualData.out === "-" ? (
                                              <span
                                                className="today-hour-wrapper"
                                                onClick={() =>
                                                  handleClockOutTimerStop(
                                                    individualData
                                                  )
                                                }
                                              >
                                                <img
                                                  src={ClockOutHourIcon}
                                                  alt="clock-out-img"
                                                />
                                              </span>
                                            ) : hasRunningCategoryToday() ? null : (
                                              <span
                                                className="today-hour-wrapper bg-success"
                                                onClick={() =>
                                                  handleClockInTimerStart(
                                                    individualData
                                                  )
                                                }
                                              >
                                                <img
                                                  src={HourIcon}
                                                  alt="hour-img"
                                                />
                                              </span>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>
                                ) : null}
                              </td>
                            </tr>
                          )}
                          {ptoStatus === "all" &&
                            individualData.week_status === 2 && (
                              <tr
                                key={`summary-${index}`}
                                className="summary-row-wrapper"
                              >
                                <td colSpan="12" className="span-week-wrapper ">
                                  <>
                                    <div className="category-sub-details category-details-wrapper">
                                      {individualData?.policy.map(
                                        (item, index) => (
                                          <>
                                            <h5 key={item.name - index}>
                                              {item.name}{" "}
                                              <span>{item.hours}</span>
                                            </h5>
                                          </>
                                        )
                                      )}
                                    </div>
                                  </>
                                </td>
                              </tr>
                            )}
                        </>
                      ))}
                </tbody>
              </table>
            </>
          ) : (
            <div className="no-data-text">
              <img src={WarningIcon} alt="warning" />
              <h5>
                {ptoStatus === "pending"
                  ? "This employee has no pending time card requests"
                  : ptoStatus === "approved"
                  ? "This employee has no approved time card requests"
                  : "This employee has no rejected time card requests"}
              </h5>
            </div>
          )}
        </div>
      </div>
      <>
        <AddModal
          clockOutModal={clockOutModal}
          handleClose={handleCloseModal}
          addModalErrors={addModalErrors}
          calcTotalhrs={calcTotalhrs}
          groupId={groupId}
          btnLoading={btnLoading}
          handleApproveData={handleApproveData}
          setLoading={setLoading}
          policyOptions={[]}
          editAddEntry={editAddEntry}
          addModalIndividualData={addModalIndividualData}
          modalInputData={modalInputData}
          handleOnChange={handleOnChange}
        />
      </>
    </>
  );
}
