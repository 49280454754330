import React, { useRef } from "react";
import { Modal, Form, FormControl, FormLabel, Spinner } from "react-bootstrap";
import closebutton from "../../assets/images/multi-card-approval/x.svg";
import Select from "react-select";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import moment from "moment";

export default function AddModal(props) {
  const {
    addModalOpen,
    handleClose,
    addModalIndividualData,
    editAddEntry,
    modalInputData,
    handleOnChange,
    addModalErrors,
    calcTotalhrs,
    groupId,
    policyOptions,
    btnLoading,
    handleApproveData,
    clockOutModal,
    jobLocation,
  } = props;

  const clockInRef = useRef(null);
  const clockOutRef = useRef(null);

  const handleTimeChange = (field) => (selectedTime) => {
    const formattedTime = moment(selectedTime).format("HH:mm");
    handleOnChange({ target: { value: formattedTime } }, field);
  };

  const handleClockIconClick = (field, ref) => () => {
    const currentTime = moment().format("HH:mm");
    handleOnChange({ target: { value: currentTime } }, field);
    if (ref.current) {
      ref.current.blur();
    }
  };

  const handleInputClick = (ref) => () => {
    if (ref.current) {
      ref.current.focus();
    }
  };
  return (
    <div className="details-modal-wrapper">
      <Modal
        show={addModalOpen || clockOutModal}
        onHide={handleClose}
        className="modal-detail-wrapper"
      >
        <Modal.Header className="modal-header-wrapper">
          <Modal.Title>
            <h4>Add new record</h4>
          </Modal.Title>
          <div className="modal-close" onClick={handleClose}>
            <img src={closebutton} alt="closebutton" />
          </div>
        </Modal.Header>
        <Modal.Body
          className={
            modalInputData?.category?.value === -1
              ? "modal-body-wrapper custom-height-wrapper"
              : "modal-body-wrapper"
          }
        >
          <div className="status-flex-container">
            <p className="day">{addModalIndividualData}</p>
          </div>
          <div className="form-wrapper">
            <Form>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div className="clock-in-out-wrapper">
                  <div className="clock-in-out">
                    <FormLabel>Clock In</FormLabel>
                    <div
                      className="sub-clock-in-out-wrapper"
                      onClick={handleInputClick(clockInRef)}
                    >
                      <TimePicker
                        disabled={!editAddEntry}
                        value={
                          modalInputData?.timeIn
                            ? moment(modalInputData?.timeIn, "HH:mm").toDate()
                            : null
                        }
                        onChange={handleTimeChange("timeIn")}
                        ref={clockInRef}
                        renderInput={(params) => (
                          <div
                            className="custom-time-picker-input"
                            onClick={handleClockIconClick("timeIn", clockInRef)}
                          >
                            {params.inputProps.children}
                          </div>
                        )}
                        ampm={true}
                        views={["hours", "minutes"]}
                        desktopModeMediaQuery="(max-width: 10000px)"
                      />
                      {addModalErrors.timeIn && (
                        <div className="invalid-feedback d-block">
                          {addModalErrors.timeIn}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="clock-in-out">
                    <FormLabel>Clock Out</FormLabel>
                    <div
                      className="sub-clock-in-out-wrapper"
                      onClick={handleInputClick(clockOutRef)}
                    >
                      <TimePicker
                        disabled={!editAddEntry}
                        value={
                          modalInputData?.timeOut
                            ? moment(modalInputData?.timeOut, "HH:mm").toDate()
                            : null
                        }
                        onChange={handleTimeChange("timeOut")}
                        ref={clockOutRef}
                        renderInput={(params) => (
                          <div
                            className="custom-time-picker-input"
                            onClick={handleClockIconClick(
                              "timeOut",
                              clockOutRef
                            )}
                          >
                            {params.inputProps.children}
                          </div>
                        )}
                        ampm={true}
                        views={["hours", "minutes"]}
                        desktopModeMediaQuery="(max-width: 10000px)"
                      />
                      {addModalErrors.timeOut && (
                        <div className="invalid-feedback d-block">
                          {addModalErrors.timeOut}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </LocalizationProvider>
              <div className="total-hours">
                <FormLabel>Total Hours</FormLabel>
                <FormControl
                  type="text"
                  placeholder=""
                  disabled={true}
                  value={calcTotalhrs}
                  onChange={(e) => handleOnChange(e, "totalHours")}
                />
              </div>
              <div className="category">
                <FormLabel>Category</FormLabel>
                <Select
                  placeholder="Select Category"
                  isDisabled={
                    !editAddEntry ||
                    groupId === 5 ||
                    groupId === 8 ||
                    (editAddEntry && groupId === 5) ||
                    (editAddEntry && groupId === 8) ||
                    clockOutModal
                  }
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  value={modalInputData?.category}
                  options={policyOptions.map((option) => ({
                    value: option.id,
                    label: option.name,
                  }))}
                  onChange={(value) => handleOnChange(value, "category")}
                  className={addModalErrors.category ? "is-invalid" : ""}
                />
                {addModalErrors.category && (
                  <div className="invalid-feedback d-block">
                    {addModalErrors.category}
                  </div>
                )}
              </div>
              {modalInputData?.category?.value === -1 && (
                <div className="job-main-location-wrapper">
                  {jobLocation?.options.length > 0 && (
                    <div className="job-location-wrapper">
                      <FormLabel>Job location</FormLabel>
                      <Select
                        placeholder="Select Location"
                        options={jobLocation?.options.map((option) => ({
                          label: option.name,
                          value: option.id,
                          ...option,
                        }))}
                        value={
                          modalInputData.jobLocation
                            ? {
                                label: modalInputData.jobLocation.name,
                                value: modalInputData.jobLocation.id,
                                ...modalInputData.jobLocation,
                              }
                            : jobLocation?.options.length === 1
                            ? {
                                label: jobLocation.options[0].name,
                                value: jobLocation.options[0].id,
                                ...jobLocation.options[0],
                              }
                            : null
                        }
                        onChange={(value) =>
                          handleOnChange(value, "jobLocation")
                        }
                      />
                      {addModalErrors.jobLocation && (
                        <div className="invalid-feedback d-block">
                          {addModalErrors.jobLocation}
                        </div>
                      )}
                    </div>
                  )}
                  {jobLocation?.code?.length > 0 && (
                    <div className="job-code-wrapper">
                      <FormLabel>Job code</FormLabel>
                      <Select
                        placeholder="Select Code"
                        options={jobLocation.code.map((option) => ({
                          label: option.name,
                          value: option.id,
                          ...option,
                        }))}
                        value={
                          modalInputData.jobCode
                            ? {
                                label: modalInputData.jobCode.name,
                                value: modalInputData.jobCode.id,
                                ...modalInputData.jobCode,
                              }
                            : jobLocation?.code?.length === 1
                            ? {
                                label: jobLocation.code[0].name,
                                value: jobLocation.code[0].id,
                                ...jobLocation.code[0],
                              }
                            : null
                        }
                        onChange={(value) => handleOnChange(value, "jobCode")}
                      />
                      {addModalErrors.jobCode && (
                        <div className="invalid-feedback d-block">
                          {addModalErrors.jobCode}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
              <div className="notes">
                <FormLabel>Notes</FormLabel>
                <FormControl
                  as="textarea"
                  disabled={!editAddEntry}
                  className="height-class-input"
                  value={modalInputData?.notes}
                  onChange={(e) => handleOnChange(e, "notes")}
                  isInvalid={!!addModalErrors.notes}
                />
                <Form.Control.Feedback type="invalid">
                  {addModalErrors.notes}
                </Form.Control.Feedback>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-add-detail-main-wrapper">
          <button
            className="approve-btn-active"
            onClick={btnLoading ? null : handleApproveData}
          >
            {btnLoading ? (
              <Spinner animation="border" size="sm" />
            ) : groupId === 5 || groupId === 8 ? (
              "ADD REQUEST"
            ) : (
              "ADD & APPROVE"
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
