import React, { useEffect, useRef } from "react";
import "./style.scss";
import OnboardHeading from "../OnboardHeading";
import DownArrow from "../../assets/images/multi-card-approval/downArrowBlue.svg";
import { Spinner } from "react-bootstrap";

export default function MultiCardHeader(props) {
  const ref = useRef(null);
  const {
    groupId,
    handleReportsOpen,
    showReports,
    ptoPendingData,
    handleReportClose,
    ptoApprovedData,
    ptoDeniedData,
    selectTagValue,
    exportCount,
    btnLoader,
    handleExportModal,
    setShowReports,
  } = props;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showReports && ref.current && !ref.current.contains(event.target)) {
        setShowReports(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showReports]);

  const labourUrl = `https://qa.guhroo.co/reports/labor_timetracker/${selectTagValue?.id}`;
  const summaryUrl = `https://qa.guhroo.co/reports/timetracker/${selectTagValue?.id}`;
  return (
    <div className="time-off-title-card multi-card-header-wrapper">
      <OnboardHeading title="TIME CARD APPROVAL" />{" "}
      {groupId === 5 || groupId === 8 ? null : (
        <div className="multi-card-go-to-reports">
          <div className="info-btn-wrapper">
            <button
              className="btn multi-approval-go-to-button"
              onClick={handleReportsOpen}
            >
              Go to reports <img src={DownArrow} alt="down-arrow" />
            </button>
            {showReports && (
              <div className="go-to-reports-sub-section" ref={ref}>
                <a
                  href={labourUrl}
                  onClick={handleReportClose}
                  className="go-to-report-lab"
                >
                  Labor Report
                </a>
                <a
                  href={summaryUrl}
                  onClick={handleReportClose}
                  className="go-to-report-summary"
                >
                  Summary Report
                </a>
              </div>
            )}
            {ptoPendingData.length > 0 ||
            ptoApprovedData.length > 0 ||
            ptoDeniedData.length > 0 ||
            selectTagValue?.status === null ? (
              <>
                {exportCount === 1 ? (
                  <button
                    className="btn btn-primary multi-approval-export-button"
                    onClick={() => (btnLoader ? null : handleExportModal())}
                  >
                    {" "}
                    {btnLoader ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Export to payroll relief"
                    )}
                  </button>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
}
