import React, { useEffect, useState } from "react";
import BackButtonComponent from "../backButton";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import logo from "../../assets/images/logo.png";
import LoginFooter from "../../pages/LoginFooter";
import "./style.scss";
import OTPInput from "otp-input-react";
import { cakePhpURL, getData, postData } from "../../api";
import Loader from "../Loader";
import { toast } from "react-toastify";
import { autocloseTiming } from "../../api/regex";
import { Modal } from "react-bootstrap";

export default function OtpVerification(props) {
  const { phoneNumber } = props;
  const [OTP, setOTP] = useState("");
  const [oTPError, setOTPError] = useState("");
  const [loading, setLoading] = useState(false);
  const userTelNumber = localStorage.getItem("userTelNumber");
  const [resendDisabled, setResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(
    parseInt(localStorage.getItem("countdown")) || 30
  );
  const [showModal, setShowModal] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [finalResendDisable, setFinalResendDisable] = useState(false);
  const [resendText, setResendText] = useState(false);
  const [, setApiPhoneNumber] = useState("");

  const handleOnSubmit = async () => {
    setBtnLoading(true);
    if (OTP.length !== 4 || isNaN(parseInt(OTP))) {
      setOTPError("Please enter a valid 4-digit OTP code");
      setBtnLoading(false);
      return;
    } else {
      let obj = {
        otp: OTP,
      };
      try {
        const res = await postData("verify-otp", {}, obj);
        if (res?.status === true) {
          setLoading(false);
          window.open(res.redirectUrl, "_self");
          setOTPError("");
          setBtnLoading(false);
          sessionStorage.removeItem("tabClosed");
          localStorage.setItem("guhroo_token", res.token);
        } else if (res?.status === false) {
          setLoading(false);
          setBtnLoading(false);
          setOTPError(res.message);
          localStorage.setItem("guhroo_token", res.token);
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };
  const handleOtpExpire = async () => {
    try {
      const res = await getData("otp-expired", {});
      if (res?.status === true) {
        setLoading(false);
        localStorage.setItem("guhroo_token", res.token);
      } else if (res?.status === false) {
        setLoading(false);
        localStorage.setItem("guhroo_token", res.token);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  const handleResendOTP = async () => {
    try {
      const res = await getData("resend-otp", {});
      if (res?.status === true) {
        setCountdown(30);
        localStorage.setItem("countdown", 30);
        setLoading(false);
        setOTPError("");
        setOTP("");
        localStorage.setItem("guhroo_token", res.token);
        toast.success(res.message, {
          theme: "colored",
          autoClose: 10000,
          style: {
            background: "#6AA84F",
            borderRadius: "16px",
          },
        });
        handleOtpExpire(res?.token);
      } else if (
        res?.status === false &&
        res?.message === "Please wait 2 minutes before requesting a new OTP"
      ) {
        localStorage.setItem("guhroo_token", res.token);
        setCountdown(120);
        localStorage.setItem("countdown", 120);
        setShowModal(true);
        setOTP("");
        setResendText(true);
        toast.error(res.message, {
          theme: "colored",
          autoClose: 10000,
          style: {
            borderRadius: "16px",
          },
        });
      } else if (
        res?.status === false &&
        res?.message ===
          "You've reached the limit for OTP requests. Please start the login process again"
      ) {
        toast.error(res.message, {
          theme: "colored",
          autoClose: 10000,
          style: {
            borderRadius: "16px",
          },
        });
        setFinalResendDisable(true);
        setTimeout(() => {
          localStorage.clear();
          window.open(cakePhpURL + "users/logout", "_self");
        }, 5000);
      } else if (res?.status === false) {
        localStorage.setItem("guhroo_token", res.token);
      } else if (res?.status === false && res?.token === "") {
        localStorage.clear();
        window.open(cakePhpURL + "users/logout", "_self");
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const getOtpData = async () => {
    setLoading(true);
    // const apiCalled = localStorage.getItem("apiCalled");
    // if (!apiCalled) {
    //   setLoading(false);
    //   return;
    // }
    const res = await getData("get-otp", {});
    if (
      res.status === true &&
      res.message === "OTP has been sent to the entered mobile number"
    ) {
      setLoading(false);
      setApiPhoneNumber(res.phone_number);
      setCountdown(30);
      localStorage.setItem("countdown", 30);
      toast.success(res.message, {
        theme: "colored",
        autoClose: 5000,
        style: {
          background: "#6AA84F",
          borderRadius: "16px",
        },
      });
      localStorage.setItem("guhroo_token", res.token);
    } else if (res.status === true) {
      localStorage.setItem("guhroo_token", res.token);
      setLoading(false);
    } else if (
      res?.status === false &&
      res?.message === "Please wait 2 minutes before requesting a new OTP"
    ) {
      localStorage.setItem("guhroo_token", res.token);
      setCountdown(120);
      localStorage.setItem("countdown", 120);
      setShowModal(true);
      setOTP("");
      setResendText(true);
      setLoading(false);
      toast.error(res.message, {
        theme: "colored",
        autoClose: 10000,
        style: {
          borderRadius: "16px",
        },
      });
    } else if (
      res?.status === false &&
      res?.message ===
        "You've reached the limit for OTP requests. Please start the login process again"
    ) {
      toast.error(res.message, {
        theme: "colored",
        autoClose: 10000,
        style: {
          borderRadius: "16px",
        },
      });
      setLoading(false);
      setFinalResendDisable(true);
      setTimeout(() => {
        localStorage.clear();
        window.open(cakePhpURL + "users/logout", "_self");
      }, 5000);
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
    // localStorage.setItem("apiCalled", true);
  };

  useEffect(() => {
    getOtpData();
  }, []);
  let formattedPhoneNumber;
  if (userTelNumber) {
    formattedPhoneNumber = `1(${userTelNumber.slice(
      0,
      3
    )}) ${userTelNumber.slice(3, 6)} - ${userTelNumber.slice(6)}`;
  } else {
    formattedPhoneNumber = `1(${phoneNumber.slice(2, 5)}) ${phoneNumber.slice(
      5,
      8
    )} - ${phoneNumber.slice(8)}`;
  }
  useEffect(() => {
    setResendDisabled(true);
    let intervalId = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
      if (countdown === 0) {
        setResendDisabled(false);
        setCountdown(0);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [countdown]);
  const formattedCountdown = `${String(Math.floor(countdown / 60)).padStart(
    2,
    "0"
  )}:${String(countdown % 60).padStart(2, "0")}`;

  useEffect(() => {
    let intervalId = setInterval(() => {
      setCountdown((prevCountdown) => {
        const newCountdown = prevCountdown - 1;
        if (newCountdown === 0) {
          setResendDisabled(false);
          clearInterval(intervalId);
        }
        localStorage.setItem("countdown", newCountdown);
        return newCountdown;
      });
    }, 900);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
  // const handleBackButtonClick = () => {
  //   localStorage.removeItem("apiCalled");
  // };
  return (
    <>
      <div className="login-body">
        <div className="login-header">
          <img src={logo} alt="logo" />
        </div>
        <section className="login-section">
          <Container className="container-padding">
            <Row className="justify-content-center align-items-center main-container-wrapper">
              <Col md={6}>
                <div className="login-box mobile-verification-wrapper">
                  <div
                    className="pt-4 ps-4 width-cus"
                    // onClick={handleBackButtonClick}
                  >
                    <BackButtonComponent hasHistoryback={true} />{" "}
                  </div>
                  <div className="mobile-info-wrapper px-4 otp-width-wrapper">
                    <h5>OTP Verification</h5>
                    <p>
                      A 4-digit code has sent to your registered mobile
                      number&nbsp;
                      {formattedPhoneNumber}.
                    </p>
                  </div>
                  <div
                    className={`${
                      oTPError ? "otp-wrapper-error" : "otp-wrapper"
                    }`}
                  >
                    <OTPInput
                      value={OTP}
                      onChange={setOTP}
                      autoFocus
                      OTPLength={4}
                      otpType="number"
                      disabled={false}
                      secure
                      className="otp-input-cus"
                    />
                    {oTPError && <p>{oTPError}</p>}
                  </div>
                  <div className="text-center mt-4 px-4">
                    <Button
                      type="submit"
                      className="btn-primary text-white"
                      onClick={btnLoading ? null : handleOnSubmit}
                      disabled={!OTP || btnLoading}
                      loading={btnLoading}
                    >
                      {btnLoading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Verify OTP"
                      )}
                    </Button>
                  </div>
                  <div
                    className={
                      resendDisabled
                        ? "my-3 resend-otp-wrapper pb-2"
                        : "my-3 resend-otp-wrapper pb-5"
                    }
                  >
                    Didn’t receive OTP?&nbsp;
                    <p
                      onClick={
                        finalResendDisable
                          ? null
                          : resendDisabled
                          ? null
                          : handleResendOTP
                      }
                      className={
                        finalResendDisable
                          ? "resend-disabled"
                          : resendDisabled
                          ? "resend-disabled"
                          : "resend-enabled"
                      }
                    >
                      Resend OTP
                    </p>
                  </div>

                  {resendDisabled && (
                    <p className="pb-3">
                      {resendText
                        ? "You can request to resend the OTP in"
                        : "Resend OTP will be enabled in"}{" "}
                      <span style={{ color: "#2773b1" }}>
                        {formattedCountdown}
                      </span>{" "}
                      seconds.
                    </p>
                  )}
                </div>
              </Col>
            </Row>
            {loading && <Loader />}
          </Container>
          <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            className="modal-email-wrapper"
          >
            <Modal.Body className="modal-email-body-wrapper">
              We've sent your OTP via both SMS and email for your convenience.
              Please check your inbox.
            </Modal.Body>
            <Modal.Footer className="modal-email-footer-wrapper">
              <Button
                variant="primary"
                onClick={() => setShowModal(false)}
                className="modal-btn-wrapper modal-email-footer-button "
              >
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </section>
      </div>
      <LoginFooter />
    </>
  );
}
